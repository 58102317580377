import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('keypress', this.handleKeyPress);
    this.element.addEventListener('paste', this.handlePaste);
  }

  handleKeyPress(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  handlePaste(event) {
    const pastedText = event.clipboardData.getData('text');
    if (!/^\d+$/.test(pastedText)) {
      event.preventDefault();
    }
  }
}
