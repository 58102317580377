import { Controller } from "@hotwired/stimulus"
import Trix from "trix"
import Rails from "@rails/ujs"
 
export default class extends Controller {
 
  
  progressbar = () => {
    let progress = document.querySelector('.appl-form__progress-bar');
    if(progress){
      progress.style.width = progress.innerHTML;
    };
  }
  
  venueselector = () => {
    const venueSelect = document.querySelector('#personal_detail_venue_id');
    const venues = [...document.querySelectorAll('.appl-form__venues--single')];
    const terms = document.querySelector('.personal_detail_accept_terms');
    
    if(venueSelect){
      if(venueSelect.value == 0){
        venueSelect.addEventListener('change', () => {
          let venueID = venueSelect.value;
          let venue = document.querySelector('#venue_' + venueID);
          venues.forEach(e => {
            e.classList.remove('active');
          });    
          venue.classList.add('active');
          terms.classList.add('active');
        });
      } else {
        let venueID = venueSelect.value;
        let venue = document.querySelector('#venue_' + venueID);
        venue.classList.add('active');
        terms.classList.add('active');
      }
    }
  }
  
  connect() {
    this.progressbar();
    this.venueselector();
  }
}




// progress bar



