import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="banner-img"
export default class extends Controller {
  static targets = ["container"]

  connect() {
    const container = this.containerTarget
    const mobileImg = container.getAttribute("data-mobile");
    const tabletImg = container.getAttribute("data-tablet");
    const desktopImg = container.getAttribute("data-desktop");


    if(window.innerWidth > 1024){                                            //desktop
      console.log('desktop size')   
      if(desktopImg !== '/' ){
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      } else if(tabletImg == '/') {
        this.containerTarget.style.backgroundImage = `url('${tabletImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      }
    } else if (window.innerWidth < 1025 && window.innerWidth > 501){         //tablet
      console.log('tablet size')   
      if(tabletImg !== '/'){
        this.containerTarget.style.backgroundImage = `url('${tabletImg}')`;
      } else if(mobileImg !== '/') {
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      }
    } else {    
      console.log('mobile size')                                                            //mobile
      if(mobileImg !== '/'){
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      } else if(tabletImg !== '/') {
        this.containerTarget.style.backgroundImage = `url('${tabletImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      }
    }
      
  }
}