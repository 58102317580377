import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prev-award"
export default class extends Controller {

  static targets = ["prevaward", "noprevaward"]

  prevaward() {
    const radioTrue = document.querySelector("#personal_statement_info_previous_award_recipient_true")
    const radioFalse = document.querySelector("#personal_statement_info_previous_award_recipient_false")
    const radios = [radioTrue, radioFalse];

    if(radioTrue.checked == true){
      this.prevawardTarget.classList.remove('hidden')
    } else if(radioFalse.checked == true){
      this.noprevawardTarget.classList.remove('hidden')
    }

    radios.forEach(radio => {
      radio.addEventListener("change", () => {
        if(radio.value == "true"){
          this.prevawardTarget.classList.remove('hidden')
          this.noprevawardTarget.classList.add('hidden')
        } else if(radio.value == "false"){
          this.noprevawardTarget.classList.remove('hidden')
          this.prevawardTarget.classList.add('hidden')
        }
      })
    });

  }

  connect() {
    this.prevaward();
  }


}
