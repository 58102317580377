import iconSlider from './icon_slider';
import imgSlider from './image_slider';
import expandablePanel from './expandable';
// import mobileMenu from './mobile-menu';
import slideShow from './slideshow';
import Carousel from "@morsedigital/vanilla-carousel";
import SwiperInit from './swiper';

// (() => {
//   mobileMenu();
// })();

(() => {
  iconSlider();
})();

(() => {
  imgSlider();
})();

(() => {
  expandablePanel();
})();

(() => {
  slideShow();
})();

(() => {
  Carousel();
})();

(() => {
  SwiperInit();
})();
