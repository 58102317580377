export default () => {
  const imgSlider = document.querySelector('.image-slider');   
  const newsLetter = document.querySelector('.newsletter-slider');
  let slideImg 
  let scrollWidth
  
  window.addEventListener('load', () => {
    if(newsLetter){
      slideImg = document.querySelector('.slide > a > img');  
    }else if(imgSlider){
      slideImg = document.querySelector('.slide > div > img');  
    }  
    if(slideImg){
      scrollWidth = slideImg.offsetWidth;
    }
  });

  window.addEventListener('resize', () => {
    if(slideImg){
      scrollWidth = slideImg.offsetWidth;
    }
  }, true);

  let imgBtnRight = document.getElementsByClassName('img-slide-right');
  let imgBtnLeft = document.getElementsByClassName('img-slide-left');
  let slideTrack = document.getElementsByClassName('imgscroll-multiple-imgs'); 
  
  const imgSlideRight = imgSlideIndex => {
    slideTrack[imgSlideIndex].scrollLeft += 2*scrollWidth;
  }

  const imgSlideLeft = imgSlideIndex => {
    slideTrack[imgSlideIndex].scrollLeft -= 2*scrollWidth;
  }
  
  if(imgBtnRight && imgBtnLeft && imgSlider) {
    [...imgBtnRight].forEach((btn, index) => {        
      btn.addEventListener('click', () => {
        imgSlideRight(index)  
      });
    });

    [...imgBtnLeft].forEach((btn, index) => {
      btn.addEventListener('click', () => {
        imgSlideLeft(index)
      });
    });
  }

  const slider = document.querySelectorAll('.imgscroll-container');
  const sliderIndicator = document.querySelector('.image-slider__indicator');
  
  if(sliderIndicator){
    slider.forEach(slider => {    
      const slides = slider.querySelectorAll('.slide'); 
      
      const observer = new IntersectionObserver((slide) => {
        slide.forEach((slide) => {
          let indicators = slide.target.closest('.imgscroll-container').querySelector('.onboarding__slide-indicator').querySelectorAll('.indicator__dots');
          let slideIndex = slide.target.getAttribute("data-index");
          indicators[slideIndex-1].classList.toggle('dots-active', slide.isIntersecting);
        })
      },{threshold:0.8,})
      
      slides.forEach((slide) => {
        observer.observe(slide);
      })

    })
  }
  
};