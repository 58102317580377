import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {

  connect() {
    const menu = document.querySelector('.mobile-nav-container');
    const hamburger = document.querySelector('.hamburger');

    hamburger.addEventListener('click', () => {
      menu.classList.toggle('open');
      hamburger.classList.toggle('toggle-hamburger');
    });

    menu.addEventListener('click', (e) => {
      const classes = ['mobile-menu', 'mobile-nav-list__item','mobile-sub-nav-list__item']
      if (classes.some(classes => e.target.classList.contains(classes))) {

      } else{
        menu.classList.toggle('open');
        hamburger.classList.toggle('toggle-hamburger');
      }
    })    

    let submenu = document.querySelectorAll('.mobile-sub-nav-list');

    [...submenu].forEach(e => {
      let btn = e.closest('.mobile-nav-list__item');
      btn.addEventListener("click",()=>{
        e.classList.toggle('open');
        btn.classList.toggle('open');
      });
    });
  }
}
