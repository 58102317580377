import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String}

  async cycle(event){
    event.preventDefault()
    const request = new FetchRequest('get', this.urlValue, { responseKind: 'turbo_stream' })
    const response = await request.perform()

    if (response.ok) {
    }
  }
}
