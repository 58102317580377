export default () => {
  const expandables = [...document.querySelectorAll('[data-attribute="expandable"]')];
  
  if(expandables){  
    expandables.forEach(expandable => {
      let button = expandable.querySelector('[data-attribute="expandable-btn"]')
      let section = expandable.querySelector('[data-attribute="expandable-section"]')
      let icon = expandable.querySelector('.expandable-icon')

      button.addEventListener('click', ()=>{
        section.classList.toggle('expandable-section--open')
        if(icon){
          icon.classList.toggle('expandable-icon--open')
        }
      })
    })
  }
  
};