export default () => {
  const iconSlider = document.querySelector('.icon-slider');   
  let slideicon = document.querySelector('.slide > div > img');  
  let scrollWidth

  if(slideicon){
    scrollWidth = slideicon.offsetWidth;
  }

  window.addEventListener('resize', () => {
    if(slideicon){
      scrollWidth = slideicon.offsetWidth;
    }
  }, true);

  let iconBtnRight = document.getElementsByClassName('icon-slide-right');
  let iconBtnLeft = document.getElementsByClassName('icon-slide-left');
  let slideTrack = document.getElementsByClassName('iconscroll-multiple-icons'); 
  
  const iconSlideRight = iconSlideIndex => {
    slideTrack[iconSlideIndex].scrollLeft += 3*scrollWidth;
  }

  const iconSlideLeft = iconSlideIndex => {
    slideTrack[iconSlideIndex].scrollLeft -= 3*scrollWidth;
  }
  
  if(iconBtnRight && iconBtnLeft && iconSlider) {
    [...iconBtnRight].forEach((btn, index) => {        
      btn.addEventListener('click', () => {
        iconSlideRight(index)  
      });
    });

    [...iconBtnLeft].forEach((btn, index) => {
      btn.addEventListener('click', () => {
        iconSlideLeft(index)
      });
    });
  }
  
};