import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["awardLevelDiv"]
  static values = { inputSelector: String }

  showSelectedAwardLevelDiv() {
    const awardSelect = document.querySelector('#personal_detail_award_level_id');
    const awardLevelDivs = document.querySelectorAll('.awardLevelDiv');

    if(awardSelect.value !== ''){
      let awardLevelDiv = document.querySelector(`.award-level-${awardSelect.value}`)
      awardLevelDiv.classList.remove('hidden');
    }

    awardSelect.addEventListener('change', () => {
      awardLevelDivs.forEach(div => {
        if(div.dataset.awardLevelId == awardSelect.value){
          div.classList.remove('hidden');
        } else if(!div.classList.contains('hidden')) {
          div.classList.add('hidden');
        }
      });
    });
  }

  connect() {
    this.showSelectedAwardLevelDiv()
  }

}

