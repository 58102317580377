import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="other-belief"
export default class extends Controller {

  otherbelief() {
    const radios = document.querySelector('.equality_monitoring_religion').querySelectorAll('input.radio_buttons')
    const textField = document.querySelector('.form-input:has(.equality_monitoring_other_religion)')
    console.log(textField);
    
    radios.forEach(radio => {
      if(radio.value == "other" && radio.checked == true){
        textField.classList.remove('hidden')
      }
      radio.addEventListener("change", () => {
        if(radio.value == "other"){
          textField.classList.remove('hidden')
        } else {
          textField.classList.add('hidden')
        }
      })  
    });
  }

  connect() {
    this.otherbelief();
  }
}
