import Swiper, { Navigation, Pagination } from 'swiper';

export default () => {
  const swiperHero = new Swiper('.swiper-hero', {
    modules: [Navigation, Pagination],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    slidesPerGroup:1,
    spaceBetween:0,
    pagination: {
      el:'.swiper-pagination-hero',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }, 
  });

};